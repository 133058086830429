body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  /*animation: App-logo-spin infinite 2s linear;*/
  height: 100px;

}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 10px;
  color: white;
}

.App-header-hidden {
  display: none;
  visibility: hidden;
}

.App-title {
  font-size: 1.1em;
}

.App-intro {
  font-size: large;
}

.App-scene {
  display: absolute;
  width: 100vw;
  height: 100vh;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.sumerian-canvas-inner-container {
  position: relative;
  overflow: hidden;
}

.sumerian {
  position: absolute;
}
